<template>
  <section>
    <view-course ref="viewCourse" @doLoad="doLoad"></view-course>
    <add-course ref="addCourse" @doLoad="doLoad"></add-course>
    <div class="mx-2">
      <v-sheet class="pa-4 rounded-lg">
        <v-toolbar flat>
          <v-btn small class="ma-2" @click="doPreviousMonth" color="primary">
            <font-awesome-icon size="lg" :icon="['fas', 'arrow-left']" fixed-width></font-awesome-icon>
          </v-btn>
          <v-btn class="ma-2" @click="doCurrentMonth" color="primary">
            Today
          </v-btn>
          <v-btn small class="ma-2" @click="doNextMonth" color="primary">
            <font-awesome-icon size="lg" :icon="['fas', 'arrow-right']" fixed-width></font-awesome-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>
            {{ activeCalendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            id="menu-activator"
            color="warning"
          >
            Add to Schedule
            <font-awesome-icon size="lg" :icon="['fas', 'plus']" class="ml-2" fixed-width></font-awesome-icon>
          </v-btn>
          <v-menu activator="#menu-activator">
            <div class="mt-14">
              <v-btn block class="mt-2" color="primary" @click="doScheduleCourse()">
                Course
              </v-btn>
              <v-btn block class="mt-2" color="primary">
                Other
              </v-btn>
            </div>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <div class="mt-2 mx-2" style=" height: calc(100vh - 120px);">
        <v-calendar
          v-model="calendar"
          :weekdays="[0, 1, 2, 3, 4, 5, 6]"
          type="month"
          ref="calendar"
          :events="calendarEvents"
          @change="doLoad"
          @click:date="showDate"
          @click:event="showEvent"
          @click:more="showMore"
          :event-height="40"
        >
          <template v-slot:event="{ event }">
            <div class="mx-1">
              <div v-if="event.type === 'course'">
                <div>
                <span class="font-weight-bold">
                  {{ $moment(event.start).format('h:mm A') }}
                </span>
                  <span style="float:right;" class="location-title-constraint">
                  <font-awesome-icon size="sm" :icon="['fas', event.location.icon]" fixed-width></font-awesome-icon>
                  <span class="hidden-md-and-down pl-1">{{ event.location.name }}</span>
                </span>
                </div>
                <div>
                <span>
                  <v-img style="display: inline-block;" contain :src="event.organization.logoUrl" width="12"></v-img>
                </span>
                  <span class="body-2">
                  {{ event.name }}
                </span>
                </div>
              </div>
              <div v-if="event.type === 'trip'">
                <div>
                  <span class="font-weight-bold">
                    Trip: {{ event.name }}
                  </span>
                  <span class="body-2">
                   {{ $moment(event.start).format('ll') }} to {{ $moment(event.end).format('ll') }}
                  </span>
                </div>
              </div>
            </div>
          </template>
        </v-calendar>
        <!--        Date click-->
        <v-menu
          v-model="isSelectedDateOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card max-width="250">
            <v-sheet class="pa-4">
              <span class="title">Create Event on {{ $moment(selectedDate).format('MMM DD') }}</span>
              <v-btn block class="mt-2" color="primary" @click="doScheduleCourse(selectedDate)">
                Course
              </v-btn>
            </v-sheet>
          </v-card>
        </v-menu>
        <!--        Event click-->
        <v-menu
          v-model="isSelectedEventOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
          style="z-index: 100;"
        >
          <v-card max-width="250" v-if="selectedEvent.type === 'course'">
            <v-card-title class="pb-1 text-center">
              <v-avatar size="32" style="width: 100%" v-if="selectedEvent.organization">
                <v-img contain :src="selectedEvent.organization.logoUrl"></v-img>
              </v-avatar>
              <div class="my-2 text-h6" v-snip="{ lines: 1, mode: 'js', midWord: true }">{{ selectedEvent.name }}</div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="4">
                  {{ $moment(selectedEvent.start).format('h:mm A') }}
                </v-col>
                <v-col align="right" v-if="selectedEvent.location">
                  <font-awesome-icon size="sm" :icon="['fas', selectedEvent.location.icon]" fixed-width></font-awesome-icon>
                  {{ selectedEvent.location.name }}
                </v-col>
              </v-row>
              <v-row class="mt-2">
                <v-col>
                  <v-btn block color="primary" @click="doViewCourse(selectedEvent.id)">
                    View Event
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="doSearchEventsByGroupUuid(selectedEvent.id, selectedEvent.groupUuid).length >= 1">
                <v-col>
                  <v-divider></v-divider>
                  <div class="text-center subtitle-1 mb-2">
                    Associated events
                  </div>
                  <span v-for="(event, index) in doSearchEventsByGroupUuid(selectedEvent.id, selectedEvent.groupUuid)" :key="index">
                    <div>
                      <span class="font-weight-bold caption ml-1">
                        {{ $moment(event.start).format('lll') }}
                      </span>
                        <span style="float:right;" class="location-title-constraint">
                        <font-awesome-icon size="sm" :icon="['fas', event.location.icon]" fixed-width></font-awesome-icon>
                        <span class="hidden-md-and-down pl-1 caption mr-1">{{ event.location.name }}</span>
                      </span>
                      </div>
                      <div>
                      <span class="body-2">
                        {{ event.name }}
                      </span>
                    </div>
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-menu>
        <!--        More click-->
        <v-menu v-model="isSelectedMoreOpen" :close-on-content-click="false" :activator="selectedElement">
          <div>
            <div v-for="(event, index) in selectedMoreDateEvents" :key="index" class="white--text mt-1 rounded" :style="`background-color: ${event.color}; cursor: pointer; width: 95%;`" @click="showEvent({ nativeEvent: $event, event: event })">
              <div v-if="event.type === 'course'">
                <div>
              <span class="font-weight-bold caption ml-1">
                {{ $moment(event.start).format('h:mm A') }}
              </span>
                  <span style="float:right;" class="location-title-constraint">
                <font-awesome-icon size="sm" :icon="['fas', event.location.icon]" fixed-width></font-awesome-icon>
                <span class="hidden-md-and-down pl-1 caption mr-1">{{ event.location.name }}</span>
              </span>
                </div>
                <div>
              <span>
                <v-img style="display: inline-block;" contain :src="event.organization.logoUrl" width="12"></v-img>
              </span>
                  <span class="body-2">
                {{ event.name }}
              </span>
                </div>
              </div>
            </div>
          </div>
        </v-menu>
      </div>
    </div>
  </section>
</template>

<script>
import ViewCourse from '@/views/admin/schedule/viewCourse'
import AddCourse from '@/views/admin/schedule/addCourse.vue'

export default {
  name: 'Calendar',
  components: {
    AddCourse,
    ViewCourse
  },
  data: () => ({
    utcTime: null,
    localTime: null,
    scheduleDialog: false,
    calendar: '',
    activeCalendar: {
      title: String(),
      start: null,
      end: null
    },
    isSelectedEventOpen: false,
    isSelectedDateOpen: false,
    isSelectedMoreOpen: false,
    selectedEvent: { },
    selectedDate: null,
    selectedMoreDateEvents: [],
    selectedElement: null,
    events: {
      courses: [],
      trips: []
    }
  }),
  methods: {
    doLoad () {
      this.doUpdateFromCalendar()
      this.doLoadCourseEvents()
      this.doLoadTripEvents()
    },
    doLoadCourseEvents () {
      this.$services.course.event.doReadByDateRange(this.activeCalendar.start, this.activeCalendar.end).then(courseEvents => {
        this.events.courses = courseEvents
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      })
    },
    doLoadTripEvents () {
      this.$services.trip.doGetAll().then(trips => {
        this.events.trips = trips
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      })
    },
    showDate ({ nativeEvent, date }) {
      const open = () => {
        this.selectedDate = this.$moment(date).toDate()
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => {
          this.isSelectedDateOpen = true
        }))
      }

      if (this.isSelectedDateOpen) {
        this.isSelectedDateOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => {
          this.isSelectedEventOpen = true
        }))
      }

      if (this.isSelectedEventOpen) {
        this.isSelectedEventOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    showMore ({ nativeEvent, date }) {
      const open = () => {
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => {
          this.isSelectedMoreOpen = true

          this.selectedMoreDateEvents = this.calendarEvents.filter(event => event.dateOnly === this.$moment(date).format('YYYY-MM-DD'))
          this.selectedMoreDateEvents.shift()
        }))
      }

      if (this.isSelectedMoreOpen) {
        this.isSelectedMoreOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    doHandleEventClick: function (event) {
    },
    doSearchEventsByGroupUuid (eventId, groupUuid) {
      return this.events.courses.filter(courseEvent => {
        if (courseEvent.id !== eventId && courseEvent.groupUuid === groupUuid) {
          return courseEvent
        }
      })
    },
    doNextMonth () {
      this.$refs.calendar.next()
    },
    doCurrentMonth () {
      this.calendar = this.$moment().format('YYYY-MM-DD')
    },
    doPreviousMonth () {
      this.$refs.calendar.prev()
    },
    doUpdateFromCalendar () {
      this.activeCalendar.title = this.$refs.calendar.title
      this.activeCalendar.start = this.$moment(this.$refs.calendar.lastStart.date).toDate()
      this.activeCalendar.end = this.$moment(this.$refs.calendar.lastEnd.date).toDate()
    },
    doScheduleCourse (date = null) {
      this.$refs.addCourse.doOpen(date)
    },
    doViewCourse (id) {
      this.$refs.viewCourse.doOpen(id)
    },
    doViewTrip () {

    }
  },
  mounted () {
    this.doLoad()
  },
  computed: {
    calendarEvents () {
      const events = []

      this.events.courses.forEach(event => {
        events.push({
          id: event.id,
          name: event.course.name,
          organization: event.course.organization,
          location: event.location,
          groupUuid: event.groupUuid,
          courseId: event.courseId,
          dateOnly: this.$moment(event.start).format('YYYY-MM-DD'),
          start: this.$moment(event.start).format('YYYY-MM-DD HH:mm'),
          color: '#16a085',
          timed: true,
          type: 'course'
        })
      })

      this.events.trips.forEach(event => {
        if ('dates' in event) {
          event.dates.forEach(date => {
            events.push({
              name: event.name,
              tripId: event.id,
              start: this.$moment(date.start).format('YYYY-MM-DD'),
              end: this.$moment(date.end).format('YYYY-MM-DD'),
              color: '#e67e22',
              timed: false,
              type: 'trip'
            })
          })
        }
      })

      return events
    },
    groupedCourseEvents () {
      const grouped = []

      this.events.courses.forEach(event => {
        const groupedCourseEvent = {
          groupUuid: event.groupUuid,
          organization: event.course.organization,
          name: event.course.name,
          events: [event]
        }

        let found = false

        grouped.forEach((groupedEvent, index) => {
          if (groupedEvent.groupUuid === event.groupUuid) {
            found = true
            grouped[index].events.push(event)
          }
        })

        if (!found) {
          grouped.push(groupedCourseEvent)
        }
      })

      return grouped
    }
  }
}
</script>

<style scoped>
::v-deep a {
  color: white !important;
}
::v-deep .v-menu__content {
  background-color: rgba(0, 0, 0, 0);
}
::v-deep .fc-day-today {

}
::v-deep .location-title-constraint {
  width: 80px;
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
