import { render, staticRenderFns } from "./addCourse.vue?vue&type=template&id=515d2886&scoped=true&"
import script from "./addCourse.vue?vue&type=script&lang=js&"
export * from "./addCourse.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "515d2886",
  null
  
)

export default component.exports