<template>
  <section>
    <v-overlay :value="isLoading" absolute opacity="0.7" z-index="1000">
      <v-progress-circular
        indeterminate
        color="accent"
        :size="50"
        :width="5"
      ></v-progress-circular>
    </v-overlay>
    <delete-course ref="deleteCourse" @didDestroy="doClose"></delete-course>
    <v-dialog v-model="isOpen" persistent max-width="1000px">
      <v-card class="ma-0 overflow-y-hidden">
        <v-card-title>
          <v-avatar>
            <v-img :src="event.course.organization.logoUrl"></v-img>
          </v-avatar>
          <span class="text-h6 ml-2">{{ event.course.name }}</span>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-2">
            <v-col md="12">
              <v-form @submit.prevent="doUpdate" ref="form">
                <v-row>
                  <v-col>
                    <div>
                      <v-date-picker
                        v-model="startDate"
                        :show-current="false"
                        header-color="primary"
                        color="red lighten-1"
                        style="width: 100%;"
                        :min="$moment().format('YYYY-MM-DD')"
                        :events="this.groupedEvents.map(event => { return this.$moment(event.start).format('YYYY-MM-DD') })"
                        event-color="primary"
                        :rules="inputs.rules.simplyRequired"
                      ></v-date-picker>
                    </div>
                  </v-col>
                  <v-col>
                    <div>
                      <v-text-field
                        label="Start time"
                        type="time"
                        suffix="MST"
                        v-model="startTime"
                        outlined
                        class="mt-4"
                        :rules="inputs.rules.simplyRequired"
                      ></v-text-field>
                    </div>
                    <div>
                      <v-select
                        outlined
                        :items="locations"
                        v-model="event.courseEventLocationId"
                        item-text="name"
                        item-value="id"
                        label="Location"
                        :rules="inputs.rules.simplyRequired"
                      >
                        <template v-slot:item="{ item }">
                          <div class="title">
                            <font-awesome-icon :icon="['fas', item.icon]" fixed-width></font-awesome-icon>
                            {{ item.name }}
                            <div class="text-caption">
                              {{ item.address }}
                            </div>
                          </div>
                        </template>
                        <template v-slot:selection="{ item }">
                        <span>
                          <font-awesome-icon :icon="['fas', item.icon]" fixed-width></font-awesome-icon>
                          {{ item.name }}
                        </span>
                        </template>
                      </v-select>
                    </div>
                  </v-col>
                </v-row>
                <div class="mt-2">
                  <v-btn @click="doClose">
                    Cancel
                  </v-btn>
                  <div style="float:right;">
                    <v-btn color="error" class="mr-4" @click="doDelete">
                      Delete
                    </v-btn>
                    <v-btn type="submit" color="primary">
                      Update
                    </v-btn>
                  </div>
                </div>
              </v-form>
            </v-col>
<!--            <v-col>-->
<!--              <v-card style="width: 100%; height: 100%;" elevation="1">-->
<!--                <v-row>-->
<!--                  <v-col md="12" class="pt-0" offset-md="2">-->
<!--                    <v-card class="text-center">-->
<!--                      <div class="title pa-4">Associated Events</div>-->
<!--                    </v-card>-->
<!--                    <v-expansion-panels class="mt-2">-->
<!--                      <v-expansion-panel v-for="(event, index) in groupedEvents" :key="index">-->
<!--                        <v-expansion-panel-header>-->
<!--                          <v-checkbox-->
<!--                            off-icon="fa-regular fa-square"-->
<!--                            :value="true"-->
<!--                            :disabled="true"-->
<!--                            @click="$event.stopPropagation()"-->
<!--                          ></v-checkbox>-->
<!--                          <v-list>-->
<!--                            <v-list-item>-->
<!--                              <v-list-item-content>-->
<!--                                <v-list-item-title>-->
<!--                                  <font-awesome-icon :icon="['fas', event.location.icon]" fixed-width></font-awesome-icon>-->
<!--                                  <span class="ml-2">{{ event.location.name }}</span>-->
<!--                                </v-list-item-title>-->
<!--                              </v-list-item-content>-->
<!--                            </v-list-item>-->
<!--                          </v-list>-->
<!--                        </v-expansion-panel-header>-->
<!--                        <v-expansion-panel-content>-->
<!--                          <v-list>-->
<!--                            <v-list-item>-->
<!--                              <v-list-item-content>-->
<!--                                <v-list-item-title>-->
<!--                                  <font-awesome-icon :icon="['fas', 'calendar-days']" fixed-width></font-awesome-icon>-->
<!--                                  <span class="ml-4">{{ $moment(event.start).format('MMM, DD YYYY') }}</span>-->
<!--                                </v-list-item-title>-->
<!--                                <v-list-item-title>-->
<!--                                  <font-awesome-icon :icon="['fas', 'clock']" fixed-width></font-awesome-icon>-->
<!--                                  <span class="ml-4">{{ $moment(event.start).format('h:mm A') }}</span>-->
<!--                                </v-list-item-title>-->
<!--                              </v-list-item-content>-->
<!--                            </v-list-item>-->
<!--                          </v-list>-->
<!--                          <v-btn @click="doOpen(event.id)" block color="primary">-->
<!--                            Edit event-->
<!--                          </v-btn>-->
<!--                        </v-expansion-panel-content>-->
<!--                      </v-expansion-panel>-->
<!--                    </v-expansion-panels>-->
<!--                  </v-col>-->
<!--                </v-row>-->
<!--              </v-card>-->
<!--            </v-col>-->
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import DeleteCourse from '@/views/admin/schedule/deleteCourse.vue'

export default {
  name: 'viewCourse',
  components: { DeleteCourse },
  data: () => ({
    isLoading: false,
    isOpen: false,
    locations: [],
    event: {
      id: Number(),
      groupUuid: String(),
      courseId: Number(),
      location: {},
      course: {
        organization: {}
      },
      start: new Date()
    },
    groupedEvents: [],
    currentlyAssociatedEvents: [],
    unassociatedEvents: []
  }),
  computed: {
    startDate: {
      get: function () {
        return this.$moment(this.event.start).format('YYYY-MM-DD')
      },
      set: function (newValue) {
        this.event.start = this.$moment(`${newValue} ${this.startTime}`).toDate()
      }
    },
    startTime: {
      get: function () {
        return this.$moment(this.event.start).format('HH:mm')
      },
      set: function (newValue) {
        this.event.start = this.$moment(`${this.startDate} ${newValue}`).toDate()
      }
    }
  },
  methods: {
    doOpen (courseEventId) {
      this.event.id = courseEventId

      this.isLoading = true

      new Promise((resolve, reject) => {
        this.$services.course.event.doReadById(this.event.id).then(event => {
          this.event = event
          this.$services.course.event.location.doReadAll().then(locations => {
            this.locations = locations
            this.$services.course.event.doReadFromCourseIdAndGatherGroups(this.event.courseId, new Date()).then(groupedEvents => {
              this.groupedEvents = groupedEvents[this.event.groupUuid].filter(event => event.id !== this.event.id)
              resolve()
            }).catch(error => {
              reject(error)
            })
          }).catch(error => {
            reject(error)
          })
        }).catch(error => {
          reject(error)
        })
      }).then(_ => {
        this.isOpen = true
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false
      })
    },
    doClose () {
      this.isOpen = false
      this.$emit('doLoad')
    },
    doUpdate () {
      if (this.event.groupUuid === String() || this.event.groupUuid === null) {
        this.event.groupUuid = this.$uuid.v4()
      }

      if (this.$refs.form.validate()) {
        this.$services.course.event.doUpdateById(this.event.id, this.event.groupUuid, this.event.courseEventLocationId, this.event.start).then(_ => {
          this.doClose()
        }).catch(error => {
          this.$root.$emit('showError', error.message)
        })
      }
    },
    doDelete () {
      this.$refs.deleteCourse.doOpen(this.event.id)
    }
  },
  mounted () {
  }
}
</script>

<style scoped>

</style>
