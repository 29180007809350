<template>
  <section>
    <v-overlay :value="isLoading" absolute opacity="0.7" z-index="1000">
      <v-progress-circular
        indeterminate
        color="accent"
        :size="50"
        :width="5"
      ></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="isOpen" persistent max-width="1000px">
      <v-card class="ma-0 overflow-y-hidden">
        <v-card-title>
          <span class="text-h5">Schedule a Course event</span>
        </v-card-title>
        <v-form @submit.prevent="doSave" ref="form">
          <v-card-text>
            <v-row>
              <v-col>
                <v-autocomplete
                  class="mt-4"
                  v-model="event.courseId"
                  :items="courses"
                  outlined
                  label="Choose a course"
                  :rules="inputs.rules.simplyRequired"
                  item-text="name"
                  item-value="id"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      @click="data.select"
                    >
                      <v-avatar left>
                        <v-img contain :src="data.item.organization.logoUrl"></v-img>
                      </v-avatar>
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-avatar>
                      <v-img contain :src="data.item.organization.logoUrl"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-scroll-y-transition>
                  <div v-if="isCourseSet">
                    <v-date-picker
                      v-model="event.selectedDate"
                      :show-current="false"
                      header-color="primary"
                      color="red lighten-1"
                      :min="$moment().format('YYYY-MM-DD')"
                      :rules="inputs.rules.simplyRequired"
                    ></v-date-picker>
                  </div>
                </v-scroll-y-transition>
              </v-col>
              <v-col>
                <v-scroll-y-transition>
                  <div v-if="isCourseSet && isDateSet">
                    <v-text-field
                      label="Start time"
                      type="time"
                      suffix="MST"
                      v-model="event.selectedTime"
                      :rules="inputs.rules.simplyRequired"
                    ></v-text-field>
                  </div>
                </v-scroll-y-transition>
                <v-scroll-y-transition>
                  <div v-if="isCourseSet && isDateSet && isTimeSet">
                    <v-select
                      outlined
                      :items="locations"
                      v-model="event.courseEventLocationId"
                      item-text="name"
                      item-value="id"
                      label="Location"
                      :rules="inputs.rules.simplyRequired"
                    >
                      <template v-slot:item="{ item }">
                        <div class="title">
                          <font-awesome-icon size="xs" :icon="['fas', item.icon]" fixed-width></font-awesome-icon>
                          {{ item.name }}
                          <div class="text-caption">
                            {{ item.address }}
                          </div>
                        </div>
                      </template>
                      <template v-slot:selection="{ item }">
                        <span>
                          <font-awesome-icon size="lg" :icon="['fas', item.icon]" fixed-width></font-awesome-icon>
                          {{ item.name }}
                        </span>
                      </template>
                    </v-select>
                  </div>
                </v-scroll-y-transition>
              </v-col>
              <v-col v-if="isGroupedCourseEventsAvailable">
                <v-scroll-y-transition>
                  <v-card v-if="isCourseSet && isDateSet && isTimeSet && isCourseEventLocationIdSet && isGroupedCourseEventsAvailable">
                    <v-card-title>
                      <span class="text-caption">Does this event occur with any grouped events?</span>
                    </v-card-title>
                    <v-card-text>
                      <v-expansion-panels flat>
                        <v-expansion-panel v-for="(groupUuid, indexA) in Object.keys(this.groupedCourseEvents)" :key="indexA">
                          <v-expansion-panel-header>
                            <v-checkbox
                              off-icon="fa-regular fa-square"
                              v-model="event.groupUuid"
                              :value="groupUuid"
                              @click="$event.stopPropagation()"
                            ></v-checkbox>
                            <v-list>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    <span>{{ courses.filter(course => course.id === groupedCourseEvents[groupUuid][0].courseId)[0].name }}</span>

                                  </v-list-item-title>
                                  <v-list-item-subtitle>
                                    {{ $moment(groupedCourseEvents[groupUuid][0].start).format('MMM, DD YYYY') }}
                                    <span v-if="groupedCourseEvents[groupUuid].length > 1">to {{ $moment(groupedCourseEvents[groupUuid][(groupedCourseEvents[groupUuid].length - 1)].start).format('MMM, DD YYYY') }}</span>
                                  </v-list-item-subtitle>
                                  <v-list-item-subtitle v-if="groupedCourseEvents[groupUuid].length > 1">
                                    <span>{{ groupedCourseEvents[groupUuid].length }} total events scheduled</span>
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-list>
                              <v-divider></v-divider>
                              <span v-for="(event, indexB) in groupedCourseEvents[groupUuid]" :key="indexB">
                                <v-list-item>
                                  <v-list-item-content>
                                    <v-list-item-subtitle>{{ $moment(event.start).format('MMM, DD YYYY - h:mm A') }}</v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                      <font-awesome-icon size="sm" :icon="['fas', event.location.icon]" fixed-width></font-awesome-icon>
                                      <span class="ml-2">{{ event.location.name }}</span>
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider></v-divider>
                              </span>
                            </v-list>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-card-text>
                  </v-card>
                </v-scroll-y-transition>
              </v-col>
            </v-row>
            <div class="mt-2">
              <v-btn @click="doClose">
                Cancel
              </v-btn>
              <v-btn type="submit" style="float:right;" color="primary" :disabled="!(isCourseSet && isDateSet && isTimeSet && isCourseEventLocationIdSet)">
                Save
              </v-btn>
            </div>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
export default {
  name: 'addCourse',
  data: () => ({
    isOpen: false,
    isLoading: false,
    event: {
      courseId: Number(),
      groupUuid: String(),
      courseEventLocationId: Number(),
      selectedDate: null,
      selectedTime: null
    },
    courses: [],
    locations: [],
    groupedCourseEvents: []
  }),
  methods: {
    doOpen (date = null) {
      this.doInit()
      this.isLoading = true

      if (date !== null) {
        this.event.selectedDate = this.$moment(date).format('YYYY-MM-DD')
      }

      new Promise((resolve, reject) => {
        this.$services.course.doGetAll().then(courses => {
          this.courses = courses
          this.$services.course.event.location.doReadAll().then(locations => {
            this.locations = locations
            resolve()
          }).catch(error => {
            reject(error)
          })
        }).catch(error => {
          reject(error)
        })
      }).then(_ => {
        this.isOpen = true
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false
      })
    },
    doSave () {
      const datetime = this.$moment(`${this.event.selectedDate} ${this.event.selectedTime}`).toDate()

      if (this.event.groupUuid === String() || this.event.groupUuid === null) {
        this.event.groupUuid = this.$uuid.v4()
      }

      if (this.$refs.form.validate()) {
        this.$services.course.event.doCreate(this.event.groupUuid, this.event.courseId, this.event.courseEventLocationId, datetime).then(_ => {
          this.doClose()
        }).catch(error => {
          this.$root.$emit('showError', error.message)
        })
      }
    },
    doClose () {
      this.$refs.form.resetValidation()

      this.isOpen = false
      this.$emit('doLoad')
    },
    doInit () {
      this.courses = []
      this.locations = []
      this.event = {
        courseId: Number(),
        groupUuid: String(),
        courseEventLocationId: Number(),
        selectedDate: null,
        selectedTime: null
      }
    }
  },
  computed: {
    isCourseSet () {
      return this.event.courseId !== 0
    },
    isDateSet () {
      return this.event.selectedDate !== null
    },
    isTimeSet () {
      return this.event.selectedTime !== null
    },
    isCourseEventLocationIdSet () {
      return this.event.courseEventLocationId !== 0
    },
    isGroupedCourseEventsAvailable () {
      return Object.keys(this.groupedCourseEvents).length !== 0
    }
  },
  watch: {
    'event.courseId': function (newValue) {
      this.isLoading = true

      this.$services.course.event.doReadFromCourseIdAndGatherGroups(newValue, new Date()).then(groupedCourseEvents => {
        this.groupedCourseEvents = groupedCourseEvents
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
