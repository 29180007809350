<template>
  <span>
    <v-overlay :value="isLoading" z-index="500">
      <div class="text-center pa-8 rounded-lg" style="width: 200px;">
         <v-progress-circular indeterminate color="white"></v-progress-circular>
      </div>
    </v-overlay>
    <v-dialog
      v-model="isOpen"
      persistent
      max-width="400"
    >
    <v-card>
      <v-card-title class="text-h5">
        <div>
          You sure you want to <span class="text-decoration-underline red--text"> Delete</span> ?
        </div>
        <div class="title mt-2">
          {{ $moment(event.start).format('lll') }} <br> at {{ event.location.name }}
        </div>
      </v-card-title>
      <v-card-text>Although deleting an event should be self-explanatory, let's provide some clarification. By deleting this event, all information related to it will be removed from Sea2Sea Scuba. It is important to understand that this action will result in the complete removal of ALL information associated with the event. Therefore, please ensure that you truly want to proceed before taking this step.</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="doClose">
          Cancel
        </v-btn>
        <v-btn color="red" text @click="doDestroy">
          Destroy
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </span>
</template>

<script>
export default {
  name: 'deleteCourse',
  data: () => ({
    isLoading: false,
    isOpen: false,
    event: {
      id: Number(),
      groupUuid: String(),
      courseId: Number(),
      start: new Date(),
      location: {}
    }
  }),
  methods: {
    doOpen (id) {
      this.event.id = id
      this.isLoading = true

      this.$services.course.event.doReadById(id).then(event => {
        this.event = event

        this.isOpen = true
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false
      })
    },
    doDestroy () {
      this.isOpen = false

      this.isLoading = true
      this.$services.course.event.doDestroy(this.event.id).then().catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.$emit('didDestroy')
        this.isLoading = false
      })
    },
    doClose () {
      this.isOpen = false
    }
  }
}
</script>

<style scoped>

</style>
